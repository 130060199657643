<template>
  <div>
    <SingleModelLayout>
      <template #title>
        <TitleSummaryCard />
      </template>
      <template #left>
        <vue-card class="lg:mt-2">
          Relations Card
        </vue-card>
      </template>
      <template #main>
        Main Content
      </template>
      <template #right>
        Sidebar Content
      </template>
    </SingleModelLayout>
  </div>
</template>
<script type="text/javascript">
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'

export default {
  name: 'Single',

  components: {
    SingleModelLayout,
    TitleSummaryCard
  }
}
</script>
